<script setup lang="ts">
import type { NuxtError } from '#app'
import consola from 'consola'

const props = defineProps({
  error: Object as () => NuxtError,
})

const localePath = useLocalePath()

if (props.error && props.error.statusCode !== 404) {
  consola.error(`Error caught by error.vue: ${props.error.message}`)
}
</script>

<template>
  <main class="flex h-dvh flex-col items-center justify-center gap-4">
    <h1 class="text-primary-500 text-center text-4xl font-bold">
      {{ $t(`shared.error.${error?.statusCode || '500'}.title`) }}
    </h1>

    <i18n-t
      scope="global"
      :keypath="`shared.error.${error?.statusCode || '500'}.description`"
      tag="p"
      class="max-w-sm text-pretty text-center text-sm leading-relaxed text-gray-600 dark:text-gray-400"
    >
      <template #homeLink>
        <br>
        <NuxtLink
          :to="localePath('/')"
          class="text-primary-600 dark:text-primary-400 hover:underline focus:underline"
        >
          {{ $t("shared.error.backToHomeLink") }}
        </NuxtLink>
      </template>
    </i18n-t>
  </main>
</template>
