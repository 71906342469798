import type { AbilitiesMap, Ability, Permission } from "./types"

type AbilityValue = { reason: string; allowed: false } | { allowed: true }

interface ResourceSpecificAbilityValue {
	[K: string]: AbilityValue
}

export function defineCanFunction(permissions: Permission[]) {
	const { can, allow, forbid } = defineAbility()

	for (const permission of permissions) {
		if (permission.allowed) {
			allow(permission)
			continue
		}

		forbid(permission)
	}

	return can
}

function defineAbility() {
	const abilities = new Map<
		Ability,
		AbilityValue | ResourceSpecificAbilityValue
	>()

	function allow<A extends Ability>({
		ability,
		resource,
	}: {
		ability: A
		resource: Permission<A>["resource"]
	}) {
		setAbility({ ability, resource, value: { allowed: true } })
	}

	function forbid<A extends Ability>({
		ability,
		resource,
		reason,
	}: {
		ability: A
		resource: Permission<A>["resource"]
		reason: string
	}) {
		setAbility({ ability, resource, value: { allowed: false, reason } })
	}

	function setAbility<A extends Ability>({
		ability,
		resource,
		value,
	}: {
		ability: A
		resource: Permission<A>["resource"]
		value: AbilityValue
	}) {
		if (!resource) {
			abilities.set(ability, value)
			return
		}

		if (resource === "*") {
			abilities.set(ability, {
				"*": value,
			})
			return
		}

		const existingValue = abilities.get(ability)
		const parsedExistingValue =
			typeof existingValue === "object" ? existingValue : {}

		const uniqueId = getUniqueId(resource)

		abilities.set(ability, {
			...parsedExistingValue,
			[uniqueId]: value,
		})
	}

	function can<A extends Ability>(ability: A, resource?: AbilitiesMap[A]) {
		const value = abilities.get(ability)

		if (!resource) {
			return isAbilityValue(value)
				? value
				: {
						allowed: false,
						reason: "INVALID_ACTION",
					}
		}

		if (isAbilityValue(value) || typeof value !== "object") {
			return {
				allowed: false,
				reason: "INVALID_ACTION",
			}
		}

		const uniqueId = getUniqueId(resource)

		if (value[uniqueId]) {
			return value[uniqueId]
		}

		if (value["*"]) {
			return value["*"]
		}

		return {
			allowed: false,
			reason: "INVALID_RESOURCE",
		}
	}

	return {
		allow,
		forbid,
		can,
	}
}

function isAbilityValue(value: unknown): value is AbilityValue {
	return !!value && typeof value === "object" && "allowed" in value
}

function getUniqueId<T extends Record<string, unknown>>(resource: T) {
	const resourceKey = Object.keys(resource)[0]!

	const value = resource[resourceKey]

	return `${resourceKey}/${value}`
}
